<form *ngIf="form" [formGroup]="form">
    <fieldset [disabled]="disableForm" class="formContainer">
        <div class="expand">
            <div class="infoHeader" data-toggle="no-collapse">
                <span translate>Business Type</span>
            </div>

            <div class="row p-3">

                <mat-radio-group aria-label="Pick User Type" class="w-100" formControlName="merchantBusinessType" (change)="onBusinessTypeChange()">
                    <mat-radio-button class="col-6 col-md-3" [value]="merchantSignUpEnum.Freelancing">{{'Freelancing' | translate}}</mat-radio-button>
                    <mat-radio-button class="col-6 col-md-3" [value]="merchantSignUpEnum.CommercialRecord"> {{'Commercial Record' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="inputs">
                <div class="inputContainer" *ngIf="!this.isCommercialRecord">
                    <div class="form-group">
                        <label *ngIf="!this.isCommercialRecord" for="nationalId"
                            [class.has-required]="!useNewSignUpForm" translate> National ID</label>

                        <input type="string" maxlength="10" class="form-control" formControlName="nationalId"
                            (keypress)="validateNumericInput($event, form.get('nationalId'))" />

                        <ng-container *ngIf="displayValidation('nationalId')">
                            <small class="text-danger" *ngIf="form.get('nationalId')?.hasError('required')"
                                translate>
                                Required
                            </small>
                            <small class="text-danger"
                                *ngIf="form.get('nationalId')?.errors?.minlength || form.get('nationalId')?.errors?.maxlength"
                                translate>
                                National ID must be 10 characters long
                            </small>
                        </ng-container>
                    </div>
                </div>

                <div class="inputContainer" *ngIf="this.isCommercialRecord">
                    <div class="form-group">

                        <label *ngIf="this.isCommercialRecord" for="crNo" [class.has-required]="!useNewSignUpForm"
                            translate> CR Number </label>
                        <input type="string" maxlength="10" class="form-control" formControlName="crNo"
                            (keypress)="validateNumericInput($event, form.get('crNo'))" />

                        <ng-container *ngIf="displayValidation('crNo')">
                            <small class="text-danger" *ngIf="form.get('crNo')?.hasError('required')" translate>
                                Required
                            </small>
                            <small class="text-danger"
                                *ngIf="form.get('crNo')?.errors?.minlength || form.get('crNo')?.errors?.maxlength"
                                translate>
                                CR Number must be 10 characters long
                            </small>
                        </ng-container>
                    </div>
                </div>

                <div class="inputContainer" formGroupName="nameTranslations">
                    <div class="form-group">
                        <label *ngIf="!this.isCommercialRecord" for="ar" translate> Full Name in Arabic</label>
                        <label *ngIf="this.isCommercialRecord" for="ar" translate>Full Entity Name in Arabic</label>
                        <input class="form-control" formControlName="ar"
                            (keypress)="arabicInput($event, form.get('nameTranslations.ar'))" />
                        <ng-container *ngIf="displayValidation('nameTranslations.ar')">
                            <small class="text-danger" *ngIf="form.get('nameTranslations.ar')?.hasError('required')"
                                translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="form.get('nameTranslations.ar')?.hasError('wrongInput')"
                                translate>
                                Keyboard must be in Arabic
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label *ngIf="!this.isCommercialRecord" for="en" translate> Full Name in English</label>
                        <label *ngIf="this.isCommercialRecord" for="en" translate> Full Entity Name in English</label>
                        <input class="form-control" formControlName="en"
                            (keypress)="englishInput($event, form.get('nameTranslations.en'))" />
                        <ng-container *ngIf="displayValidation('nameTranslations.en')">
                            <small class="text-danger" *ngIf="form.get('nameTranslations.en')?.hasError('required')"
                                translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="form.get('nameTranslations.en')?.hasError('wrongInput')"
                                translate>
                                Keyboard must be in English
                            </small>
                        </ng-container>
                    </div>
                </div>

                <div class="inputContainer" formGroupName="officialNameTranslations">
                    <div class="form-group">
                        <label *ngIf="!this.isCommercialRecord" for="ar" translate>Short Name in Arabic</label>
                        <label *ngIf="this.isCommercialRecord" for="ar" translate>Short Entity Name in Arabic </label>
                        <input class="form-control" formControlName="ar"
                            (keypress)="arabicInput($event, form.get('officialNameTranslations.ar'))" />
                        <div class="text-danger"
                            *ngIf="form.get('officialNameTranslations.ar')?.dirty || form.get('officialNameTranslations.ar')?.touched">
                            <small *ngIf="form.get('officialNameTranslations.ar')?.hasError('required')">
                                {{'Required' | translate}}
                            </small>
                            <small *ngIf="form.get('officialNameTranslations.ar')?.hasError('wrongInput')">
                                {{'Keyboard must be in English' | translate}}
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label *ngIf="!this.isCommercialRecord" for="ar" translate>Short Name in English</label>
                        <label *ngIf="this.isCommercialRecord" for="ar" translate>Short Entity Name in English </label>

                        <input class="form-control" formControlName="en"
                            (keypress)="englishInput($event, form.get('officialNameTranslations.en'))" />

                        <div class="text-danger"
                            *ngIf="form.get('officialNameTranslations.en')?.dirty || form.get('officialNameTranslations.en')?.touched">
                            <small *ngIf="form.get('officialNameTranslations.en')?.hasError('required')">
                                {{'Required' | translate}}
                            </small>
                            <small *ngIf="form.get('officialNameTranslations.en')?.hasError('wrongInput')">
                                {{'Keyboard must be in English' | translate}}
                            </small>
                        </div>
                    </div>
                </div>

                <ng-container class="inputContainer">
                    <div class="form-group">
                        <label for="specialization" [class.has-required]="!useNewSignUpForm"
                            translate>Specialization</label>
                        <input type="text" class="form-control" formControlName="specialization" placeholder="{{'Food' | translate}}" />
                        <app-input-required-error-message [control]="form.get('specialization') || undefined">
                        </app-input-required-error-message>

                    </div>
                </ng-container>

                <div class="inputContainer">
                    <div class="form-group col-12" *ngIf="!useNewSignUpForm || isEditing"
                        [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                        <label for="issuingDate" [class.has-required]="!useNewSignUpForm" translate>Issuing Date</label>
                        <div class="inputGroup">
                            <span class="inputGroupAddon dob">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M6.94 2c.416 0 .753.324.753.724v1.46c.668-.012 1.417-.012 2.26-.012h4.015c.842 0 1.591 0 2.259.013v-1.46c0-.4.337-.725.753-.725s.753.324.753.724V4.25c1.445.111 2.394.384 3.09 1.055c.698.67.982 1.582 1.097 2.972L22 9H2v-.724c.116-1.39.4-2.302 1.097-2.972c.697-.67 1.645-.944 3.09-1.055V2.724c0-.4.337-.724.753-.724Z" />
                                    <path fill="currentColor"
                                        d="M22 14v-2c0-.839-.004-2.335-.017-3H2.01c-.013.665-.01 2.161-.01 3v2c0 3.771 0 5.657 1.172 6.828C4.343 22 6.228 22 10 22h4c3.77 0 5.656 0 6.828-1.172C22 19.658 22 17.772 22 14Z"
                                        opacity=".5" />
                                    <path fill="currentColor"
                                        d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z" />
                                </svg>
                            </span>
                            <span class="input-group">
                                <input formControlName="issuingDate" type="date"
                                    class="form-control addon" onkeydown="() => {return false}" />
                            </span>
                        </div>
                        <app-input-required-error-message [control]="form.get('issuingDate') || undefined">
                        </app-input-required-error-message>
                    </div>
                </div>

                <div class="inputContainer">
                    <div class="form-group col-12" *ngIf="!useNewSignUpForm || isEditing"
                        [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                        <label for="expirationDate" [class.has-required]="!useNewSignUpForm" translate>Expiration
                            Date</label>
                        <div class="inputGroup">
                            <span class="inputGroupAddon dob">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M6.94 2c.416 0 .753.324.753.724v1.46c.668-.012 1.417-.012 2.26-.012h4.015c.842 0 1.591 0 2.259.013v-1.46c0-.4.337-.725.753-.725s.753.324.753.724V4.25c1.445.111 2.394.384 3.09 1.055c.698.67.982 1.582 1.097 2.972L22 9H2v-.724c.116-1.39.4-2.302 1.097-2.972c.697-.67 1.645-.944 3.09-1.055V2.724c0-.4.337-.724.753-.724Z" />
                                    <path fill="currentColor"
                                        d="M22 14v-2c0-.839-.004-2.335-.017-3H2.01c-.013.665-.01 2.161-.01 3v2c0 3.771 0 5.657 1.172 6.828C4.343 22 6.228 22 10 22h4c3.77 0 5.656 0 6.828-1.172C22 19.658 22 17.772 22 14Z"
                                        opacity=".5" />
                                    <path fill="currentColor"
                                        d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z" />
                                </svg>
                            </span>
                            <span class="input-group">
                                <input formControlName="expirationDate" type="date"
                                    class="form-control addon" onkeydown="() => {return false}" />
                            </span>
                        </div>
                        <app-input-required-error-message [control]="form.get('expirationDate') || undefined">
                        </app-input-required-error-message>
                    </div>
                </div>

                <div *ngIf="!this.isCommercialRecord" class="inputContainer">
                    <div class="form-group">
                        <label for="authorizedDocumentId" [class.has-required]="!useNewSignUpForm" translate>
                            Authorized Document ID
                        </label>
                        <input type="text" class="form-control" formControlName="authorizedDocumentId" />
                        <app-input-required-error-message [control]="form.get('authorizedDocumentId') || undefined">
                        </app-input-required-error-message>

                    </div>
                </div>
            </div>
        </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="no-collapse">
                <span translate>{{ "ADDRESS_INFO.TITLE" }}</span>
            </div>
            <div class="collapse show inputs" formGroupName="streetAddress">
                <div class="inputContainer">
                    <div class="form-group">
                        <label for="unitNo" translate>{{'ADDRESS_INFO.UNIT_NUMBER'}}</label>
                        <input
                            class="form-control"
                            formControlName="unitNo"
                            (keypress)="validateNumericInput($event, form.get('streetAddress.unitNo'))" >
                        <div *ngIf="displayValidation('streetAddress.unitNo')">
                            <small class="text-danger"
                                *ngIf="form.get('streetAddress.unitNo')?.hasError('required')" translate>
                                Required
                            </small>
                            <small class="text-danger"
                                *ngIf="form.get('streetAddress.unitNo')?.hasError('wrongInput')" translate>
                                Must be numberic
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="buildingNo" translate>{{
                            "ADDRESS_INFO.BUILDING_NUMBER"
                            }}</label>
                        <input class="form-control" formControlName="buildingNo" (keypress)="
                                validateNumericInput(
                                    $event,
                                    form.get('streetAddress.buildingNo')) " />
                        <div *ngIf="displayValidation('streetAddress.buildingNo')">
                            <small class="text-danger" *ngIf="
                                    form.get('streetAddress.buildingNo')?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form.get('streetAddress.buildingNo')?.hasError('wrongInput')" translate>
                                Must be numberic
                            </small>
                        </div>
                    </div>
                </div>
                <div class="inputContainer">
                    <div class="form-group">
                        <label for="street" translate>{{
                            "ADDRESS_INFO.STREET_NAME"
                            }}</label>
                        <input type="text" class="form-control" formControlName="street" />

                        <app-custom-forms-validation [control]="form.get('streetAddress.street') || undefined"></app-custom-forms-validation>
                    </div>
                    <div class="form-group">
                        <label for="district" translate>{{
                            "ADDRESS_INFO.DISTRICT"
                            }}</label>
                        <input type="text" class="form-control" formControlName="district" />

                        <app-custom-forms-validation [control]="
                                form.get('streetAddress.district') || undefined"></app-custom-forms-validation>
                    </div>
                </div>
                <div class="inputContainer">
                    <div class="form-group">
                        <label for="zipCode" translate>{{
                            "ADDRESS_INFO.ZIP_CODE"
                            }}</label>
                        <input class="form-control" formControlName="zipCode" (keypress)="
                                validateNumericInput(
                                    $event,
                                    form.get(
                                        'streetAddress.zipCode'))" />
                        <div *ngIf="
                                displayValidation(
                                    'streetAddress.zipCode'
                                )
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'streetAddress.zipCode'
                                        )
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'streetAddress.zipCode'
                                        )
                                        ?.hasError('wrongInput')
                                " translate>
                                Must be numberic
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="additional" translate>{{
                            "ADDRESS_INFO.ADDITIONAL_NUMBER"
                            }}</label>
                        <input type="number" class="form-control" formControlName="additional" (keypress)="
                                validateNumericInput(
                                    $event,
                                    form.get(
                                        'streetAddress.additional'
                                    )
                                )
                            " />
                        <div *ngIf="
                                displayValidation(
                                    'streetAddress.additional'
                                )
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'streetAddress.additional'
                                        )
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'streetAddress.additional'
                                        )
                                        ?.hasError('wrongInput')
                                " translate>
                                Must be numberic
                            </small>
                        </div>
                    </div>
                </div>
                <div class="inputContainer">
                    <div class="form-group">
                        <label for="country" translate>{{
                            "ADDRESS_INFO.COUNTRY"
                            }}</label>
                        <ng-select [items]="countryOptions" [clearable]="false" bindLabel="label"
                            bindValue="value" formControlName="country"></ng-select>

                        <app-custom-forms-validation [control]="
                                form.get('streetAddress.country') ||
                                undefined
                            "></app-custom-forms-validation>
                    </div>
                    <div class="form-group">
                        <label for="city" translate>{{
                            "ADDRESS_INFO.CITY"
                            }}</label>
                        <ng-select [items]="cityOptions" [clearable]="false" bindLabel="label"
                            bindValue="value" formControlName="city"></ng-select>

                        <app-custom-forms-validation [control]="
                                form.get('streetAddress.city') ||
                                undefined
                            "></app-custom-forms-validation>
                    </div>
                </div>
            </div>
        </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="no-collapse">
                <span translate>{{ "VAT" }}</span>
            </div>
            <div class="collapse show inputs">
                <div class="form-group">
                    <div class="row pick-subscription">
                        <mat-radio-group aria-label="subscriptionType" class="w-100" formControlName="hasVAT" (change)="onVatChange($event)">
                            <mat-radio-button class="col-6 col-md-3" [value]="true" 
                                >{{'Yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-6 col-md-3" [value]="false"
                                translate>{{'No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="form-group">
                    <label for="vatNo" translate>VAT No.</label>
                    <input type="text" [readOnly]="!form.get('hasVAT')?.value" class="form-control" formControlName="vatNo" aria-describedby="VAT_input"
                        (keypress)="
                            validateNumericInput(
                                $event,
                                form.get('vatNo')
                            )
                        " maxlength="15" />
                    <div *ngIf="displayValidation('vatNo')">
                        <small class="text-danger" *ngIf="
                        form
                            .get('vatNo')
                            ?.hasError('required')
                        " translate>
                        Required
                        </small>
                        <small class="text-danger" *ngIf="
                                form.get('vatNo')?.hasError('wrongInput')
                            " translate>
                            Must be numberic
                        </small>
                        <small class="text-danger" *ngIf="
                                form.get('vatNo')?.hasError('minlength')
                            " translate>
                            Must be 15 digits
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="no-collapse">
                <span translate>Bank Information</span>
            </div>
            <div class="collapse show inputs" formGroupName="bankInformation">
                <div class="row">
                    <div class="form-group">
                        <label for="bank" translate>Bank Name</label>
                        <ng-select [items]="bankOptions" [clearable]="false" bindLabel="label"
                            bindValue="value" formControlName="bank"></ng-select>

                        <app-custom-forms-validation [control]="
                                form.get('bankInformation.bank') ||
                                undefined
                            "></app-custom-forms-validation>
                    </div>

                    <div class="form-group">
                        <label for="accountName" translate>Bank Account Name</label>
                        <input type="text" class="form-control" formControlName="accountName" />
                        
                        <app-custom-forms-validation [control]="
                                form.get(
                                    'bankInformation.accountName'
                                    ) || undefined
                            "></app-custom-forms-validation>
                    </div>
                    
                    <div class="form-group">
                        <label for="IBAN" translate>IBAN</label>
                        <div class="inputGroup">
                            <label class="inputGroupAddon" translate>SA</label>
                            <input #IBAN class="form-control addon" formControlName="IBAN" maxLength="22"
                                (keypress)="
                                    validateNumericInput(
                                        $event,
                                        form.get('bankInformation.IBAN')
                                    )
                                " placeholder="xx xxxx xxxx xxxx xxxx xxxx" />
                        </div>
                        <div *ngIf="
                                displayValidation(
                                    'bankInformation.IBAN'
                                )
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get('bankInformation.IBAN')
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get('bankInformation.IBAN')
                                        ?.hasError('wrongInput')
                                " translate>
                                Must be numberic
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get('bankInformation.IBAN')
                                        ?.hasError('minlength')
                                " translate>
                                Must be 22 digits
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="accountNo" translate>Bank Account No.</label>
                        <input class="form-control" formControlName="accountNo" (keypress)="
                                validateNumericInput(
                                    $event,
                                    form.get(
                                        'bankInformation.accountNo'
                                    )
                                )
                            " />
                        <div *ngIf="
                                displayValidation(
                                    'bankInformation.accountNo'
                                )
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'bankInformation.accountNo'
                                        )
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'bankInformation.accountNo'
                                        )
                                        ?.hasError('wrongInput')
                                " translate>
                                Must be numberic
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="no-collapse">
                <span translate>{{ "CONTACT_INFO.TITLE" }}</span>
            </div>
            <div class="collapse show inputs" formGroupName="contactDetails">
                <div class="inputContainer" formGroupName="contactPerson">
                    <div class="form-group">
                        <label for="ar" translate>Name In Arabic</label>
                        <input type="text" class="form-control" formControlName="ar" (keypress)="
                                arabicInput(
                                    $event,
                                    form.get('contactDetails.contactPerson.ar')
                                )
                            " />
                        <div *ngIf="
                                displayValidation('contactDetails.contactPerson.ar')
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get('contactDetails.contactPerson.ar')
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get('contactDetails.contactPerson.ar')
                                        ?.hasError('wrongInput')
                                " translate>
                                Keyboard must be in Arabic
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="ar" translate>Name In English</label>
                        <input type="text" class="form-control" formControlName="en" (keypress)="
                                englishInput(
                                    $event,
                                    form.get(
                                        'contactDetails.contactPerson.en'
                                    )
                                )
                            " />
                        <div *ngIf="
                                displayValidation(
                                    'contactDetails.contactPerson.en'
                                )
                            ">
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'contactDetails.contactPerson.en'
                                        )
                                        ?.hasError('required')
                                " translate>
                                Required
                            </small>
                            <small class="text-danger" *ngIf="
                                    form
                                        .get(
                                            'contactDetails.contactPerson.en'
                                        )
                                        ?.hasError('wrongInput')
                                " translate>
                                Keyboard must be in English
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="mobileNo" translate>{{
                        "CONTACT_INFO.MOBILE"
                        }}</label>
                    <div class="inputGroup">
                        <label class="inputGroupAddon">+966</label>
                        <input #mobileNo class="form-control addon" formControlName="mobileNo" maxLength="9"
                            (keypress)="
                                validateNumericInput(
                                    $event,
                                    form.get('contactDetails.mobileNo')
                                )
                            " placeholder="5xxxxxxxx" />
                    </div>
                    <div *ngIf="displayValidation('contactDetails.mobileNo')">
                        <small class="text-danger" *ngIf="
                                form
                                    .get('contactDetails.mobileNo')
                                    ?.hasError('required')
                            " translate>
                            Required
                        </small>
                        <small class="text-danger" *ngIf="
                                form
                                    .get('contactDetails.mobileNo')
                                    ?.hasError('pattern')
                            " translate>
                            Must start with 5
                        </small>
                        <small class="text-danger" *ngIf="
                                form
                                    .get('contactDetails.mobileNo')
                                    ?.hasError('minlength')
                            " translate>
                            Must be 9 digits
                        </small>
                        <small class="text-danger" *ngIf="
                                form
                                    .get('contactDetails.mobileNo')
                                    ?.hasError('wrongInput')
                            " translate>
                            Must be numberic
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phone" translate>{{
                        "CONTACT_INFO.PHONE"
                        }}</label>
                    <input type="number" class="form-control" formControlName="phoneNo" />

                    <ng-container *ngIf="displayValidation('contactDetails.phoneNo')">
                        <small class="text-danger" *ngIf="form.get('contactDetails.phoneNo')?.hasError('required')"
                            translate>
                            Required
                        </small>
                    </ng-container>
                </div>
                <div class="form-group">
                    <label for="email" translate>{{
                        "CONTACT_INFO.EMAIL"
                        }}</label>
                    <input type="text" class="form-control" formControlName="email" />

                    <ng-container *ngIf="displayValidation('contactDetails.email')">
                        <small class="text-danger" *ngIf="
                        form
                            .get('contactDetails.email')
                            ?.hasError('required')
                    " translate>
                        Required
                    </small>
                        <small
                        *ngIf="form.get('contactDetails.email')?.errors?.email"
                        class="text-danger"
                        translate
                    >
                    Email must be valid
                    </small>
                    </ng-container>
                </div>
                <div class="form-group">
                    <label for="nationalId"
                        [class.has-required]="!useNewSignUpForm" translate> National ID</label>

                    <input type="string" maxlength="10" class="form-control" formControlName="nationalId"
                        (keypress)="validateNumericInput($event, form.get('nationalId'))" />

                    <ng-container *ngIf="displayValidation('contactDetails.nationalId')">
                        <small class="text-danger" *ngIf="form.get('contactDetails.nationalId')?.hasError('required')"
                            translate>
                            Required
                        </small>
                        <small class="text-danger"
                            *ngIf="form.get('contactDetails.nationalId')?.errors?.minlength || form.get('contactDetails.nationalId')?.errors?.maxlength"
                            translate>
                            National ID must be 10 characters long
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="expand">
            <div class="infoHeader" data-toggle="collapse">
                <span translate>Order</span>

            </div>
            <div class="inputs collapse show mt-3" formGroupName="order">
                <div class="d-flex flex-column gap-3">
                    <div class="texts">
                        <p translate>Monthly Subscription = 345 SAR per user</p>
                        <p translate>Weekly Subscription = 115 SAR per user</p>
                        <p translate>Device = 805 SAR*</p>
                        <p translate>*Note that any NFC enabled Android device may be used</p>
                    </div>

                    <div class="row pick-subscription">
                        <label for="subscriptionType" translate>Subscriptions Type</label>
                        <mat-radio-group aria-label="subscriptionType" class="w-100" formControlName="subscriptionType">
                            <mat-radio-button class="col-6 col-md-3" [value]="subscriptionTypeEnum.Weekly">{{'Weekly' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-6 col-md-3" [value]="subscriptionTypeEnum.Monthly">{{'Monthly' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="inputContainer">
                        <div class="form-group">
                            <label for="duration" [class.has-required]="!useNewSignUpForm" translate>Duration</label>
                            <input type="number" class="form-control" formControlName="duration" />
                            <app-input-required-error-message [control]="form.get('order.duration') || undefined">
                            </app-input-required-error-message>

                        </div>
                    </div>

                    <div class="inputContainer">
                        <div class="form-group col-12" *ngIf="!useNewSignUpForm || isEditing"
                            [ngClass]="isEditing ? 'col-sm-3' : 'col-sm-4'">
                            <label for="startDate" [class.has-required]="!useNewSignUpForm" translate>Start Date</label>
                            <div class="inputGroup">
                                <span class="inputGroupAddon dob">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                            d="M6.94 2c.416 0 .753.324.753.724v1.46c.668-.012 1.417-.012 2.26-.012h4.015c.842 0 1.591 0 2.259.013v-1.46c0-.4.337-.725.753-.725s.753.324.753.724V4.25c1.445.111 2.394.384 3.09 1.055c.698.67.982 1.582 1.097 2.972L22 9H2v-.724c.116-1.39.4-2.302 1.097-2.972c.697-.67 1.645-.944 3.09-1.055V2.724c0-.4.337-.724.753-.724Z" />
                                        <path fill="currentColor"
                                            d="M22 14v-2c0-.839-.004-2.335-.017-3H2.01c-.013.665-.01 2.161-.01 3v2c0 3.771 0 5.657 1.172 6.828C4.343 22 6.228 22 10 22h4c3.77 0 5.656 0 6.828-1.172C22 19.658 22 17.772 22 14Z"
                                            opacity=".5" />
                                        <path fill="currentColor"
                                            d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z" />
                                    </svg>

                                </span>
                                <span class="input-group">
                                    <input formControlName="startDate" type="date"
                                        class="form-control addon" onkeydown="() => {return false}" />
                                </span>
                            </div>
                            <app-input-required-error-message [control]="form.get('order.startDate') || undefined">
                            </app-input-required-error-message>
                        </div>
                    </div>

                    <div class="row pick-subscription">
                        <label for="devices" translate>Devices</label>
                        <mat-radio-group aria-label="devices" formControlName="devices" (change)="onDevicesChange($event)">
                            <mat-radio-button class="col-6 col-md-3" [value]="true" translate>{{'Yes' | translate}}</mat-radio-button>
                            <mat-radio-button class="col-6 col-md-3" [value]="false" translate>{{'No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="inputContainer">
                        <div class="form-group">
                            <label for="noOfDevicesOrUsers *"
                                [class.has-required]="!useNewSignUpForm" translate>
                                Number of Devices</label>
                            <input type="number" [readOnly]="!form.get('order')?.get('devices')?.value" class="form-control" formControlName="noOfDevicesOrUsers" />
                            <app-input-required-error-message
                                [control]="form.get('order.noOfDevicesOrUsers') || undefined">
                            </app-input-required-error-message>

                        </div>
                    </div>
                    <!-- <p translate>Quick Summary</p>
                    <p *ngIf="!this.isMonthly" class="pay-amount" translate> You will pay 690 SAR.</p>
                    <p *ngIf="this.isMonthly" class="pay-amount" translate> You will pay 1035 SAR.</p> -->

                </div>
            </div>
        </div>
        <!-- Upload Documents -->
        <div class="expand">
            <div class="infoHeader" data-toggle="collapse">
                <span translate>Upload Documents</span>

            </div>
            <div class="inputs collapse show" formGroupName="order">
                <div class="d-flex flex-column gap-3">

                    <app-merchant-sign-up-info-form [form]="uploadDocForm" [dataForm]="form" (submitInfo)="submit()">
                    </app-merchant-sign-up-info-form>

                </div>
            </div>
        </div>
    </fieldset>
</form>
<div class="row mt-4" [hidden]="!captchaRequired">
    <div class="d-flex justify-content-center">
        <re-captcha
            id="recaptcha"
            name="recaptcha"
            (resolved)="captchaResolved($event)"
            (error)="captchaErrored($event)"
            errorMode="handled">
        </re-captcha>
    </div>
</div>
<div class="d-flex justify-content-center my-4 fw-bolder gap-2 mw-100" *ngIf="!signupRequest">
    <input name="terms" type="checkbox" class="form-check-input checkbox" (change)="toggleCheckBox()">
    <label class="termsText" for="terms"> <span translate>I acknowledge that I have read and accepted the </span><a
            target="_blank"
            [href]="currentLanguage === 'ar' ? 'https://waki.sa/ar/terms-and-conditions/' : 'https://waki.sa/en/terms-and-conditions/'"
            translate>terms and conditions</a>.</label>
</div>
<div class="row" [ngClass]="{ 'mt-5 mb-5': signupRequest, 'mt-2': !signupRequest }">
    <div class="d-flex justify-content-center gap-3">
        <app-primary-button text="Submit" addClass="submit" (clickButton)="submit()" *ngIf="!signupRequest"
            [disabled]="shouldDisableSignUpButton || isLoading || !checkboxValue || !this.uploadDocForm?.valid"></app-primary-button>
        <ng-container *ngIf="signupRequest">
            <ng-container *ngIf="requestPending">
                <app-primary-button text="Save" (click)="submit()"
                    [disabled]="shouldDisableSignUpButton || isLoading"></app-primary-button>
                <button type="button" class="btn btn-outline-success btn-sm px-4" (click)="approve.emit()"
                    [disabled]="shouldDisableSignUpButton || isLoading ">
                    <i class="fa fa-check"></i>
                    {{'Approve' | translate}}
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm px-4" (click)="reject.emit()"
                    [disabled]="isLoading">
                    <i class="fa fa-times"></i>
                    {{'Reject' | translate}}
                </button>
            </ng-container>
            <app-secondary-button text="Exit" (click)="exit.emit()" [disabled]="isLoading"></app-secondary-button>
        </ng-container>
    </div>
</div>