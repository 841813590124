/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@portal/auth/services/auth.service';
import * as _ from 'lodash';
import { WakiDatePipe } from '../pipes/waki-date.pipe';
import { SharedModule } from '../shared.module';
import { isNullOrEmptyString } from '../functions/string.function';

@Injectable({
    providedIn: SharedModule
})
export class UtilitiesService {
    constructor(
        private wakiDatePipe: WakiDatePipe,
        private authService: AuthService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    public getDateAndTime(date: Date): { date: string, time: string } {
        const dateAndTime = this.wakiDatePipe.transform(date, true)?.split(' ');

        return {
            date: dateAndTime[0] || '',
            time: `${dateAndTime[1]}` || ''
        };
    };

    public generateExcelFileName(tableName: string, appendPostfix = true): string {
        const currentDateTime = this.getDateAndTime(new Date());

        let fileName = this.replaceAll(tableName, ' ', '_');

        if (appendPostfix) {
            fileName += `_${currentDateTime.date}_${currentDateTime.time.replace(' ', '-').replace(':', '-')}`;
        }

        const hasAdmin = this.authService.isAdminOrSuperAdmin();
        const hasCanteen = this.authService.isCanteenUser() || this.authService.isSuperCanteenUser();

        if (appendPostfix && !hasAdmin && hasCanteen) {
            fileName = `${this.authService.getUserCanteenCode()}_${fileName}`;
        }

        return fileName;
    };

    public translate(key: string | undefined): string {
        if (!key || !key.trim()) return '';
        return this.translateService.instant(key) || key;
    }

    public getRouteQueryParams(): Params {
        const queryParams = this.route.snapshot.queryParams;
        Object.keys(this.route.snapshot.queryParams).forEach((key: string) => {
            if (this.isNullOrEmpty(queryParams[key])) {
                delete queryParams[key];
            }
        });
        return queryParams;
    }

    public updateQueryParamsWithValues(values: any, isMerge: boolean = false) {
        const queryParams = this.route.snapshot.queryParams;
        Object.keys({ ...queryParams, ...values }).forEach((key: string) => {
            if (this.isNullOrEmpty(values[key])) delete values[key];
        });

        this.router.navigate([],
            {
                relativeTo: this.route,
                queryParams: values,
                ...isMerge ? { queryParamsHandling: 'merge' } : {}
            }
        );

        return values;
    }

    public isNullOrEmpty(value: any): boolean {
        return value === null || value === undefined || _.trim(value) === '';
    }

    public trackByFn(index: number, item: any) {
        return item ? item._id : index;
    }

    public replaceAll(s: string, searchValue: string, replaceValue: string): string {
        return s.replace(new RegExp(searchValue, 'g'), replaceValue);
    }

    public getUrlToHyperLink(rootUrl: string, params: Object): string {
        if (_.isEmpty(params)) {
            return rootUrl;
        }

        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                rootUrl += `${!rootUrl.includes("?") ? "?" : "&"}${key}=${value}`;
            }
        })
        console.log(rootUrl)
        return rootUrl;
    }

    public getFileNameFromUrl(downloadUrl: string): string {
        if (isNullOrEmptyString(downloadUrl)) {
            return '';
        }
        return downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1, downloadUrl.lastIndexOf("."));
    }
}
